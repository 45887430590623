
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    options: {
      type: Array,
      required: true,
      default: [
        'Aujourd\'hui',
        'Demain',
        'Après-demain'
      ]
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0
    },
    input: Function
  },
  mounted () {
    this.$emit('input', this.selected)
  }
})

export default class SelectDay extends Vue {
  name = 'select-day'
  options!: Array<string>
  selected = this.options.length > 0 ? 'Choisir la date' : null
  open = false
  firstTimeOpen = false
}
