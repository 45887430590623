
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    priceValue: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      formatedPrice: this.priceValue / 100
    };
  },
  watch: {
    priceValue(newValue: number) {
      // Mettre à jour la valeur formatée lorsque le prix change
      this.formatedPrice = newValue / 100;
    }
  }
})

export default class Price extends Vue {
  name = 'price'
}
