
import { Options, Vue } from 'vue-class-component'
import LogoBrand from '@/components/logo/LogoBrand.vue'

@Options({
  components: {
    LogoBrand
  },
  props: {
    brandLabel: String,
    brandImage: String
  }
})
export default class MainHeader extends Vue {
  brandLabel!: string
  brandImage!: string
  name = 'main-header'
}
