
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    time: String
  }
})

export default class Clock extends Vue {
  time!: string
  name = 'clock-component'
}
