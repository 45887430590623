
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    alt: {
      type: String,
      default: 'Brand'
    },
    size: {
      type: String,
      default: 'medium'
    },
    src: String
  },
  computed: {
    imgSrc() {
      const logoUrl = `${process.env.VUE_APP_LOGOS_BUCKET}/${this.src}`
      return this.imgError || this.src === undefined
        ? require('@/assets/images/logo.png')
        : logoUrl
    }
  }
})
export default class LogoBrand extends Vue {
  alt!: string
  size!: string
  src!: string
  name = 'logo-brand'
}
