
import { Options, Vue } from "vue-class-component";
import Kiosque from "./views/Kiosque.vue";

@Options({
  components: {
    Kiosque,
  }
})
export default class App extends Vue {}
