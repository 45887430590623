import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["k-flex k-column", `${_ctx.name}`])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["k-flex k-spaceBetweenX", `${_ctx.name}__top`])
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(`${_ctx.name}__hall`)
      }, " → " + _toDisplayString(_ctx.hall), 3),
      _createElementVNode("div", {
        class: _normalizeClass(`${_ctx.name}__department`)
      }, _toDisplayString(_ctx.address) + " — " + _toDisplayString(_ctx.number), 3)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(`${_ctx.name}__name`)
    }, _toDisplayString(_ctx.show), 3),
    _createElementVNode("div", {
      class: _normalizeClass(`${_ctx.name}__bottom`)
    }, _toDisplayString(_ctx.spectacle), 3)
  ], 2))
}