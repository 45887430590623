import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-076f68de"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onMouseenter", "onMouseleave"]
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_clock_component = _resolveComponent("clock-component")!
  const _component_theatre = _resolveComponent("theatre")!
  const _component_places = _resolveComponent("places")!
  const _component_price = _resolveComponent("price")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`${_ctx.name}`)
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.combined, (item) => {
      return (_openBlock(), _createElementBlock(_Fragment, {
        key: `seance-${item.id}`
      }, [
        _createElementVNode("div", {
          class: _normalizeClass([`${_ctx.name}__row`, "k-flex kiosque-desktop"]),
          onMouseenter: ($event: any) => (_ctx.updateStyle(item.id, true)),
          onMouseleave: ($event: any) => (_ctx.updateStyle(item.id, false))
        }, [
          _createElementVNode("div", {
            class: _normalizeClass([`${_ctx.name}__date ${
            _ctx.hover && _ctx.hoverId === item.id ? 'active' : ''
          }`, "k-fillRemaining"])
          }, _toDisplayString(_ctx.processDate(item.timestamp)), 3),
          _createElementVNode("div", {
            class: _normalizeClass(`${_ctx.name}__separator`)
          }, null, 2),
          _createElementVNode("div", {
            class: _normalizeClass([`${_ctx.name}__clock ${
            _ctx.hover && _ctx.hoverId === item.id ? 'active' : ''
          }`, "k-fillRemaining"])
          }, [
            _createVNode(_component_clock_component, {
              time: _ctx.processHour(item.timestamp)
            }, null, 8, ["time"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(`${_ctx.name}__separator`)
          }, null, 2),
          _createElementVNode("div", {
            class: _normalizeClass([`${_ctx.name}__theatre ${
            _ctx.hover && _ctx.hoverId === item.id ? 'active' : ''
          }`, "k-fillRemaining"])
          }, [
            _createVNode(_component_theatre, {
              hall: item.auditorium,
              address: item.address,
              number: item.number,
              spectacle: item.spectacle,
              show: item.title
            }, null, 8, ["hall", "address", "number", "spectacle", "show"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(`${_ctx.name}__separator`)
          }, null, 2),
          _createElementVNode("div", {
            class: _normalizeClass([`${_ctx.name}__places`, "k-fillRemaining"])
          }, [
            _createVNode(_component_places, {
              id: item.id,
              availability: item.availability
            }, null, 8, ["id", "availability"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(`${_ctx.name}__separator`)
          }, null, 2),
          _createElementVNode("div", {
            class: _normalizeClass([`${_ctx.name}__price`, "k-fillRemaining"])
          }, [
            _createVNode(_component_price, {
              "price-value": item.price
            }, null, 8, ["price-value"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(`${_ctx.name}__separator`)
          }, null, 2),
          _createElementVNode("div", {
            class: _normalizeClass([`${_ctx.name}__action`, "k-fillRemaining"])
          }, [
            _createElementVNode("button", {
              type: "button",
              name: "today",
              class: "btn btn-primary reservez",
              onClick: ($event: any) => (_ctx.onClick(item.url, item.availability))
            }, _toDisplayString(item.availability > 0 ? 'Réservez' : 'Autres Tarifs'), 9, _hoisted_2)
          ], 2)
        ], 42, _hoisted_1),
        _createElementVNode("div", {
          class: _normalizeClass([`${_ctx.name}__row`, "k-flex kiosque-mobile"])
        }, [
          _createElementVNode("div", {
            class: _normalizeClass([`${_ctx.name}__clock`, "k-fillRemaining"])
          }, [
            _createTextVNode(_toDisplayString(_ctx.processDate(item.timestamp)) + " ", 1),
            _createVNode(_component_clock_component, {
              time: _ctx.processHour(item.timestamp)
            }, null, 8, ["time"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass([`${_ctx.name}__theatre`, "k-fillRemaining"])
          }, [
            _createVNode(_component_theatre, {
              hall: item.auditorium,
              address: item.address,
              department: item.department,
              show: item.title
            }, null, 8, ["hall", "address", "department", "show"]),
            _createElementVNode("div", {
              class: _normalizeClass(`${_ctx.name}__places-mobile`)
            }, [
              _createVNode(_component_places, {
                id: item.id,
                availability: item.availability
              }, null, 8, ["id", "availability"]),
              _createVNode(_component_price, {
                "price-value": item.price
              }, null, 8, ["price-value"]),
              _createElementVNode("button", {
                type: "button",
                name: "today",
                class: "btn btn-primary reservez",
                onClick: ($event: any) => (_ctx.onClick(item.url, item.availability))
              }, _toDisplayString(item.availability > 0 ? 'Réservez' : 'Autres Tarifs'), 9, _hoisted_3)
            ], 2)
          ], 2)
        ], 2)
      ], 64))
    }), 128))
  ], 2))
}