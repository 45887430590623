import { createApp } from 'vue'
import App from './App.vue'
import createServer from './api/mock'

import './assets/scss/main.scss'

createApp(App).mount('#app')

if (process.env.NODE_ENV === 'mock') {
  createServer()
}
