import { SeanceType } from '../models'

import http from './http'
let ApiUrl = ''
if (process.env.NODE_ENV === 'local') {
  ApiUrl = process.env.VUE_APP_API
}
const isMockEnabled = process.env.NODE_ENV === 'mock'

const sendToBottomPassed = (data: SeanceType[]) => {
  const newData: SeanceType[] = []
  const newDataZero: SeanceType[] = []
  data.forEach((element: SeanceType) => {
    if (parseInt(element.timestamp) < Math.floor(Date.now() / 1000)) {
      element.availability = 0
      newDataZero.push(element)
    } else {
      newData.push(element)
    }
  })
  newData.sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1))
  newDataZero.sort((a, b) => (a.timestamp < b.timestamp ? 1 : -1))
  const finaleArray = newData.concat(newDataZero)
  return finaleArray
}

export const getSeancesData = async (department: string | undefined, day: string | undefined): Promise<SeanceType[] | null> => {
  if (day) {
    day = day.toLowerCase()
    day = day.replace('\'', '')
    day = day.replace(' ', '-')
    day = day.replace('è', 'e')
  }
  let filter = ''
  if (department) {
    filter = `department=${department}`
  } else if (day) {
    filter = `day=${day}`
  }

  if (department && day) {
    filter = `department=${department}&day=${day}`
  }
  const url = `${ApiUrl}/api/kiosque/list${filter ? `?${filter}` : ''}`
  try {
    const response = await http.get(url)

    if (response.data) {
      // Mock response.data is being returned in an array,
      // response from server is an object.
      return isMockEnabled ? response.data[0] : sendToBottomPassed(response.data)
    }
  } catch (error) {
    console.log(error)
  }
  return null;
}
