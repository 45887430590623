
import { Vue } from 'vue-class-component'

export default class FooterMain extends Vue {
  name = 'footer-main'

  onClick(url: string): null {
    window.open(url, '_blank')
    return null
  }
}
