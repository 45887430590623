
import { Options, Vue } from 'vue-class-component'
import ClockComponent from '@/components/kiosque/ClockComponent.vue'
import Theatre from '@/components/kiosque/Theatre.vue'
import Places from '@/components/kiosque/Places.vue'
import Price from '@/components/kiosque/Price.vue'

import { convertDate } from '@/utils'

import { SeanceType } from '../../models'

@Options({
  components: {
    ClockComponent,
    Theatre,
    Places,
    Price
  },
  props: {
    seancesData: Array
  }
})
export default class Grid extends Vue {
  name = 'grid'
  hoverId = 1000000
  hover = false
  combined: SeanceType[] = []
  seancesData!: Array<SeanceType>

  onClick(kiosqueUrl: string, availability: number): null {
    const extraUrl = availability > 0 ? '?kld=kiosque' : ''
    window.open(kiosqueUrl + extraUrl, '_blank')
    return null
  }

  updateStyle(id: number, hover: boolean): null {
    this.hover = hover
    this.hoverId = id
    return null
  }

  processHour(dateTime: number): string {
    return convertDate(dateTime, true)
  }

  processDate(dateTime: number): string {
    return convertDate(dateTime)
  }

  beforeMount(): null {
    this.combined = this.seancesData
    return null
  }
}
