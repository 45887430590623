import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4759a326"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["tabindex"]
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`${_ctx.name}`),
    tabindex: _ctx.tabindex,
    onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.open = false))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["selected", {open: _ctx.open}]),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.open = !_ctx.open))
    }, _toDisplayString(_ctx.selected), 3),
    _createElementVNode("div", {
      class: _normalizeClass(["items", {selectHide: !_ctx.open}])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, i) => {
        return (_openBlock(), _createElementBlock("div", {
          key: i,
          class: "item",
          onClick: ($event: any) => {_ctx.selected=option; _ctx.open=false; _ctx.$emit('input', option)}
        }, _toDisplayString(option), 9, _hoisted_2))
      }), 128))
    ], 2)
  ], 42, _hoisted_1))
}