import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["k-flex k-column", `${_ctx.name}`])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["k-flex k-spaceBetweenX", `${_ctx.name}__top`])
    }, "   ", 2),
    _createElementVNode("div", {
      class: _normalizeClass(`${_ctx.name}__number`)
    }, _toDisplayString(_ctx.priceValue === 0 ? '*' : _ctx.formatedPrice), 3),
    _createElementVNode("div", {
      class: _normalizeClass(`${_ctx.name}__bottom`)
    }, " Euros ", 2)
  ], 2))
}