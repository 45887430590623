export const convertDate = (dateTime: number, isHour = false): string => {
  let date = new Date(dateTime * 1000).toLocaleString('fr-FR', { timeZone: 'Europe/Paris' })
  const spliter = date.includes(',') ? ',' : ' '
  if (isHour) {
    date = date.split(spliter)[1].substr(-8)
    date = date.replace(':', 'h')
  }
  date = date.split(spliter)[0].substr(0, 5)
  date = date.replace('/', '.')
  return date
}
