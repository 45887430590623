import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f8f3d498"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main-navbar" }
const _hoisted_2 = { class: "navigation k-flex" }
const _hoisted_3 = ["disabled"]
const _hoisted_4 = ["disabled"]
const _hoisted_5 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("nav", null, [
      _createElementVNode("ul", _hoisted_2, [
        _createElementVNode("li", null, [
          _createElementVNode("button", {
            type: "button",
            name: "today",
            class: "btn btn-primary",
            disabled: _ctx.loading,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (this.$emit('select', 'aujourdhui')))
          }, " Aujourd'hui ", 8, _hoisted_3)
        ]),
        _createElementVNode("li", null, [
          _createElementVNode("button", {
            type: "button",
            name: "tomorrow",
            class: "btn btn-primary",
            disabled: _ctx.loading,
            onClick: _cache[1] || (_cache[1] = ($event: any) => (this.$emit('select', 'demain')))
          }, " Demain ", 8, _hoisted_4)
        ]),
        _createElementVNode("li", null, [
          _createElementVNode("button", {
            type: "button",
            name: "aft-tomorrow",
            class: "btn btn-primary",
            disabled: _ctx.loading,
            onClick: _cache[2] || (_cache[2] = ($event: any) => (this.$emit('select', 'apres-demain')))
          }, " Après-demain ", 8, _hoisted_5)
        ])
      ])
    ])
  ]))
}