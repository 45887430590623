
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    show: {
      type: String,
      default: ''
    },
    hall: {
      type: String,
      default: ''
    },
    address: {
      type: String,
      default: ''
    },
    number: {
      type: String,
      default: ''
    },
    spectacle: {
      type: String,
      default: ''
    }
  }
})
export default class Theatre extends Vue {
  hall!: string
  author!: string
  name = 'theatre'
}
