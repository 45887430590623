import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-22ab81d6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "marquee" }
const _hoisted_2 = { class: "marquee__content" }
const _hoisted_3 = { class: "marquee__item" }
const _hoisted_4 = { class: "marquee__item" }
const _hoisted_5 = { class: "marquee__item" }
const _hoisted_6 = { class: "marquee__item" }
const _hoisted_7 = { href: "/" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_logo_brand = _resolveComponent("logo-brand")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("ul", _hoisted_2, [
        _createElementVNode("li", _hoisted_3, [
          _createElementVNode("h4", {
            class: _normalizeClass(`${_ctx.name}__subtitle`)
          }, " → Des places de spectacle de dernière minute ", 2)
        ]),
        _createElementVNode("li", _hoisted_4, [
          _createElementVNode("h4", {
            class: _normalizeClass(`${_ctx.name}__subtitle`)
          }, " → Des places de spectacle de dernière minute ", 2)
        ]),
        _createElementVNode("li", _hoisted_5, [
          _createElementVNode("h4", {
            class: _normalizeClass(`${_ctx.name}__subtitle`)
          }, " → Des places de spectacle de dernière minute ", 2)
        ]),
        _createElementVNode("li", _hoisted_6, [
          _createElementVNode("h4", {
            class: _normalizeClass(`${_ctx.name}__subtitle`)
          }, " → Des places de spectacle de dernière minute ", 2)
        ])
      ])
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(`${_ctx.name}`)
    }, [
      _createElementVNode("a", _hoisted_7, [
        _createElementVNode("div", {
          class: _normalizeClass([`${_ctx.name}__content`, "k-pullLeft"])
        }, [
          _createVNode(_component_logo_brand, {
            size: "large",
            alt: _ctx.brandLabel,
            src: _ctx.brandImage
          }, null, 8, ["alt", "src"]),
          _createElementVNode("div", {
            class: _normalizeClass(`${_ctx.name}__details`)
          }, [
            _createElementVNode("span", {
              class: _normalizeClass(`${_ctx.name}__title`)
            }, _toDisplayString(_ctx.brandLabel), 3)
          ], 2)
        ], 2)
      ])
    ], 2)
  ], 64))
}