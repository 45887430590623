
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    options: {
      type: Array,
      required: true
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0
    },
    input: Function
  },
  mounted () {
    this.$emit('input', this.selected)
  }
})

export default class SelectDepartment extends Vue {
  name = 'select-department'
  options!: Array<string>
  selected = this.options.length > 0 ? 'Choisir le département' : null
  open = false
  firstTimeOpen = false
}
