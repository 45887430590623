
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Options, Vue } from 'vue-class-component'
import MainHeader from '@/components/header/MainHeader.vue'
import SelectDepartment from '@/components/common/SelectDepartment.vue'
import SelectDay from '@/components/kiosque/SelectDay.vue'
import MainNav from '@/components/kiosque/MainNav.vue'
import Grid from '@/components/kiosque/Grid.vue'
import FooterMain from '@/components/kiosque/FooterMain.vue'
import { getSeancesData } from '@/services/seances'

import { SeanceType } from '../models'

@Options({
  components: {
    MainHeader,
    SelectDepartment,
    SelectDay,
    MainNav,
    Grid,
    FooterMain
  }
})
export default class Kiosque extends Vue {
  name = 'kiosque'
  seances: SeanceType[] | null = null
  loading = true
  hasError = false
  day = ''
  department = ''
  arrayOptions = [
    'Var',
    'Bouches-du-Rhône',
    'Vaucluse',
    'Alpes-Maritimes',
    'Hautes-Alpes',
    'Alpes-de-Haute-Provence'
  ]

  arrayDayOptions = ["Aujourd'hui", 'Demain', 'Après-demain']

  onSelect(department: string) {
    if (department === 'Choisir le département') {
      return
    } else {
      this.arrayOptions = [
        'Tous les départements',
        'Var',
        'Bouches-du-Rhône',
        'Vaucluse',
        'Alpes-Maritimes',
        'Hautes-Alpes',
        'Alpes-de-Haute-Provence'
      ]
    }
    this.department = department === 'Tous les départements' ? '' : department
    this.day = ''
    this.callSeances()
  }

  onSelectDay(day: string) {
    if (day === 'Choisir la date') {
      return
    } else {
      this.arrayDayOptions = [
        'Tous les dates',
        "Aujourd'hui",
        'Demain',
        'Après-demain'
      ]
    }
    this.day = day === 'Tous les dates' ? '' : day
    this.callSeances()
  }

  onDaySelect(day: string) {
    this.day = day
    this.callSeances()
  }

  async mounted() {
    this.department = ''
    this.day = ''
    this.callSeances()
  }

  async callSeances() {
    this.loading = true
    try {
      this.seances = await getSeancesData(this.department, this.day)
      if (this.seances) {
        this.hasError = false
      } else {
        this.hasError = true
      }
    } catch (error) {
      console.log(error)
      this.hasError = true
    }
    this.loading = false
  }
}
