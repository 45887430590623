
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    availability: Number,
    id: Number
  }
})

export default class Places extends Vue {
  name = 'places'
  id!: number
  formatedPlace = 0
}
