import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '../../assets/images/logo.png'


const _withScopeId = n => (_pushScopeId("data-v-7b80b8e3"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("img", {
    class: _normalizeClass(`${_ctx.name} ${_ctx.name}--${_ctx.size}`),
    alt: `${_ctx.alt} logo`,
    src: _imports_0
  }, null, 10, _hoisted_1))
}